import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, timeInterval } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { Storage } from '@ionic/storage';
import { User } from './user';
import { AuthResponse } from './auth-response';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { RPC_URL } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTH_SERVER_ADDRESS: string =  RPC_URL + 'jwtauth.php?action=login';

  isLoggedIn = false;
  
  token: any;
  accessExpires: any;
  
  constructor(
    private router: Router,
    private storage: Storage,
    public toastController: ToastController, 
    private httpClient: HttpClient
  ) { }

  login(user: User): Observable<AuthResponse> {
    //console.log("Trying to log in to " + this.AUTH_SERVER_ADDRESS, user);
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}`, user).pipe(
      tap( (res: any) => {
        //console.log("Before processing, res is ", res)
        if(res.user) {
          //console.log("Returned from login with success.  Res:", res);
          this.storage.set('ACCESS_TOKEN', res.access_token)
            .then((response) => {
              //console.log("token stored");
            },
            error => console.error('Error storing item', error)
          );

          //RDK Convert seconds to milliseconds for javascript timer
          this.storage.set('ACCESS_EXPIRES', res.expires_in * 1000 + Date.now());
          this.token = res.access_token;
          this.accessExpires = res.expires_in+Date.now();
          this.isLoggedIn = true;
          return this.token;
        }
        //console.log("Unsuccessful login");
        this.isLoggedIn = false;
        delete this.token;
        return {};
      })
    )
  }

  logout() {
    this.storage.remove("ACCESS_TOKEN").then(() => {
      this.isLoggedIn = false;
      delete this.token;
      this.storage.remove("ACCESS_EXPIRES")
      delete this.accessExpires;
      this.router.navigate(['login']);
    });
  }

  async getToken() {
    //console.log("Getting token");
    await this.storage.get('ACCESS_EXPIRES'). then (
      data=> {
        //console.log("Got token exipiration time in  auth.service.ts getToken");
        this.accessExpires = data;
      },
      error => {
        console.error("Error getten token expiration time in auth.service.ts getToken");
        this.accessExpires = Date.now();
      }
    )
    return this.storage.get('ACCESS_TOKEN').then(
      data => {
        let currentTime = Date.now();
        //console.log("Current time and token expiry:", currentTime, this.accessExpires)
        this.token = data;
        if(this.token != null && currentTime < this.accessExpires) {
          this.isLoggedIn=true;
        } else {
          this.isLoggedIn=false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
      }
    );
  }
}
