import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivate {
  constructor(
    private router: Router,
    public authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
      const loggedIn = this.authService.isLoggedIn;
      if (loggedIn) {
        //console.log("auth.guard.ts - is currently logged in, returning true");
        return true;
      } 

      this.router.navigate(['login']);
    }
}
